<template>
    <div class="mainWrapper pb-0">
        <section class="blockElement space position-relative reatesPage">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-between">
                            <h1 class="mb-2 f-22">{{$t('printableTerms.termsText1')}}</h1>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="static">
                            <a href="/terms-of-service-eu" class="link">{{$t('printableTerms.termsText2')}}</a>
                            <p> {{$t('printableTerms.termsText3')}} </p>
                            <h3>{{$t('printableTerms.termsText4')}}</h3>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText5')}}</h5>
                            <p> {{$t('printableTerms.termsText6')}} </p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText7')}}</h5>
                            <p> {{$t('printableTerms.termsText8')}} </p>
                            <p> {{$t('printableTerms.termsText9')}} </p>
                            <p> {{$t('printableTerms.termsText10')}} </p>
                            <p> {{$t('printableTerms.termsText11')}} </p>
                            <p>{{$t('printableTerms.termsText12')}}</p>
                            <p> {{$t('printableTerms.termsText13')}} </p>
                            <p> {{$t('printableTerms.termsText14')}} </p>
                            <h3 class="headerTerms">{{$t('printableTerms.termsText15')}}</h3>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText16')}}</h5>
                            <p v-html="$t('printableTerms.termsText17')"></p>
                            <p> {{$t('printableTerms.termsText18')}} </p>
                            <p> {{$t('printableTerms.termsText19')}} </p>
                            <p> {{$t('printableTerms.termsText20')}} </p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText21')}}</h5>
                            <p> {{$t('printableTerms.termsText22')}} </p>
                            <p> {{$t('printableTerms.termsText23')}} </p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText24')}}</h5>
                            <p>{{$t('printableTerms.termsText25')}}</p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText26')}}</h5>
                            <p>{{$t('printableTerms.termsText27')}}</p>
                            <p> {{$t('printableTerms.termsText28')}} </p>
                            <p> {{$t('printableTerms.termsText29')}} </p>
                            <p> {{$t('printableTerms.termsText30')}} </p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText31')}}</h5>
                            <p> {{$t('printableTerms.termsText32')}} </p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText33')}}</h5>
                            <p> {{$t('printableTerms.termsText34')}}</p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText35')}}</h5>
                            <p>{{$t('printableTerms.termsText36')}}</p>
                            <p>{{$t('printableTerms.termsText37')}}</p>
                            <p>{{$t('printableTerms.termsText38')}}</p>
                            <p>{{$t('printableTerms.termsText39')}}</p>
                            <p>{{$t('printableTerms.termsText40')}}</p>
                            <p> {{$t('printableTerms.termsText41')}} </p>
                            <p>{{$t('printableTerms.termsText42')}}</p>
                            <p>{{$t('printableTerms.termsText43')}}</p>
                            <p>{{$t('printableTerms.termsText44')}} </p>
                            <p>{{$t('printableTerms.termsText45')}}</p>
                            <p v-html="$t('printableTerms.termsText46')"></p>
                            <p>{{$t('printableTerms.termsText47')}}</p>
                            <p>{{$t('printableTerms.termsText48')}}</p>
                            <p>{{$t('printableTerms.termsText49')}} </p>
                            <p> {{$t('printableTerms.termsText50')}} </p>
                            <p class="marginLeftTerms">{{$t('printableTerms.termsText51')}}</p>
                            <p class="marginLeftTerms">{{$t('printableTerms.termsText52')}} </p>
                            <p class="marginLeftTerms">{{$t('printableTerms.termsText53')}} </p>
                            <p class="marginLeftTerms">{{$t('printableTerms.termsText54')}} </p>
                            <p class="marginLeftTerms">{{$t('printableTerms.termsText55')}} </p>
                            <p v-html="$t('printableTerms.termsText56')"> </p>
                            <p>{{$t('printableTerms.termsText57')}} </p>
                            <p>{{$t('printableTerms.termsText58')}}</p>
                            <p>{{$t('printableTerms.termsText59')}} </p>
                            <p>{{$t('printableTerms.termsText60')}} </p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText61')}}</h5>
                            <p>{{$t('printableTerms.termsText62')}} </p>
                            <p>{{$t('printableTerms.termsText63')}} </p>
                            <p>{{$t('printableTerms.termsText64')}} </p>
                            <p v-html="$t('printableTerms.termsText65')"></p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText66')}}</h5>
                            <p>{{$t('printableTerms.termsText67')}} </p>
                            <h3 class="headerTerms">{{$t('printableTerms.termsText68')}}</h3>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText69')}}</h5>
                            <p>{{$t('printableTerms.termsText70')}}</p>
                            <p>{{$t('printableTerms.termsText71')}} </p>
                            <p>{{$t('printableTerms.termsText72')}} </p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText73')}}</h5>
                            <p>{{$t('printableTerms.termsText74')}} </p>
                            <p class="marginLeftTerms">{{$t('printableTerms.termsText75')}}</p>
                            <p class="marginLeftTerms">{{$t('printableTerms.termsText76')}} </p>
                            <p class="marginLeftTerms">{{$t('printableTerms.termsText77')}}</p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText78')}}</h5>
                            <p>{{$t('printableTerms.termsText79')}}</p>
                            <p>{{$t('printableTerms.termsText80')}} </p>
                            <p>{{$t('printableTerms.termsText81')}} </p>
                            <p v-html="$t('printableTerms.termsText82')"></p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText83')}}</h5>
                            <p>{{$t('printableTerms.termsText84')}} </p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText85')}}</h5>
                            <p>{{$t('printableTerms.termsText86')}} </p>
                            <h3 class="headerTerms">{{$t('printableTerms.termsText87')}}</h3>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText88')}}</h5>
                            <p>{{$t('printableTerms.termsText89')}} </p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText90')}}</h5>
                            <p>{{$t('printableTerms.termsText91')}}</p>
                            <p>{{$t('printableTerms.termsText92')}} </p>
                            <p>{{$t('printableTerms.termsText93')}}</p>
                            <p>{{$t('printableTerms.termsText94')}} </p>
                            <p>{{$t('printableTerms.termsText95')}} </p>
                            <p>{{$t('printableTerms.termsText96')}} </p>
                            <p v-html="$t('printableTerms.termsText97')"> </p>
                            <h3 class="headerTerms">{{$t('printableTerms.termsText98')}}</h3>
                            <p>{{$t('printableTerms.termsText99')}}</p>
                            <p class="marginLeftTerms">{{$t('printableTerms.termsText100')}}</p>
                            <p class="marginLeftTerms">{{$t('printableTerms.termsText101')}}</p>
                            <p class="marginLeftTerms">{{$t('printableTerms.termsText102')}} </p>
                            <p class="marginLeftTerms">{{$t('printableTerms.termsText103')}}</p>
                            <h3 class="headerTerms">{{$t('printableTerms.termsText104')}}</h3>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText105')}}</h5>
                            <p>{{$t('printableTerms.termsText106')}} </p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText107')}}</h5>
                            <p>{{$t('printableTerms.termsText108')}}</p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText109')}}</h5>
                            <p>{{$t('printableTerms.termsText110')}} </p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText111')}}</h5>
                            <p v-html="$t('printableTerms.termsText112')"> </p>
                            <h3 class="headerTerms">{{$t('printableTerms.termsText113')}}</h3>
                            <p>{{$t('printableTerms.termsText114')}} </p>
                            <p>{{$t('printableTerms.termsText115')}}</p>
                            <p>{{$t('printableTerms.termsText116')}} </p>
                            <p>{{$t('printableTerms.termsText117')}} </p>
                            <p>{{$t('printableTerms.termsText118')}} </p>
                            <p>{{$t('printableTerms.termsText119')}} </p>
                            <p style="font-style: italic;">{{$t('printableTerms.termsText120')}}</p>
                            <h3 class="headerTerms">{{$t('printableTerms.termsText121')}}</h3>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText122')}}</h5>
                            <p>{{$t('printableTerms.termsText123')}}</p>
                            <p class="marginLeftTerms">{{$t('printableTerms.termsText124')}} </p>
                            <p class="marginLeftTerms">{{$t('printableTerms.termsText125')}}</p>
                            <p class="marginLeftTerms">{{$t('printableTerms.termsText126')}}</p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText127')}}</h5>
                            <p>{{$t('printableTerms.termsText128')}} </p>
                            <p>{{$t('printableTerms.termsText129')}} </p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText130')}}</h5>
                            <p>{{$t('printableTerms.termsText131')}}
                                
                            </p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText132')}}</h5>
                            <p>{{$t('printableTerms.termsText133')}}</p>
                            <p>{{$t('printableTerms.termsText134')}} </p>
                            <h5 class="subHeaderTerms">{{$t('printableTerms.termsText135')}}</h5>
                            <p>{{$t('printableTerms.termsText136')}}</p>
                            <p>{{$t('printableTerms.termsText137')}} </p>
                            <p>{{$t('printableTerms.termsText138')}}</p>
                            <p>{{$t('printableTerms.termsText139')}}</p>
                            <p>{{$t('printableTerms.termsText140')}}</p>
                            <p style="text-align: right;" v-html="$t('printableTerms.termsText141')"></p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>